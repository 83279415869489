import React, { Fragment, useCallback, useMemo, useState } from 'react';
import map from 'lodash/map';

import { CreateMockMessageWithFormDataProject } from '../../../../../../../../../../messages/components/list/ItemMessagesList/hooks/useCreateMockMessageWithFormData';
import { DashboardActiveTaskProjectMessagesHeaderProject } from '../DashboardActiveTaskProjectMessagesHeader/DashboardActiveTaskProjectMessagesHeader.types';
import { ItemMessagesListMessages } from '../../../../../../../../../../messages/components/list/ItemMessagesList';

import {
  FETCH_PROJECT_MESSAGES,
  FetchProjectMessagesQueryResponse
} from '../../../../../../../../../../messages/queries/fetchProjectMessages.query';

import { useCurrentUser } from '../../../../../../../../../../../auth/hooks/useAuth';
import { useMessagesSettings } from '../../../../../../../../../../messages/hooks/useMessagesSettings';
import { useProjectMessagesContentCreateMessage } from '../../../../../../../../../../projects/components/content/ProjectMessagesContent/hooks/useProjectMessagesContentCreateMessage';
import { useMessagesWithCustomPagination } from '../../../../../../../../../../messages/hooks/useMessagesWithCustomPagination';

import { DashboardActiveTaskProjectMessagesHeader } from '../DashboardActiveTaskProjectMessagesHeader';
import { ProjectMessagesContentMessagesList } from '../../../../../../../../../../projects/components/content/ProjectMessagesContent/components/ProjectMessagesContentMessagesList';
import {
  ItemCreateMessageForm,
  ItemCreateMessageFormStateScope,
  useItemCreateMessageFormState
} from '../../../../../../../../../../messages/components/forms/ItemCreateMessageForm';

import {
  chatGptMessagesTypes,
  MessagesPermissions
} from '../../../../../../../../../../messages/messagesConstants';
import { ProjectsPermissions } from '../../../../../../../../../../projects/projectsConstants';
import { ProjectCache } from '../../../../../../../../../../projects/ProjectCache';

type DashboardActiveTaskProjectMessagesContentProject =
  CreateMockMessageWithFormDataProject &
    DashboardActiveTaskProjectMessagesHeaderProject;

interface DashboardActiveTaskProjectMessagesContentProps {
  project: DashboardActiveTaskProjectMessagesContentProject;
}

function DashboardActiveTaskProjectMessagesContent({
  project
}: DashboardActiveTaskProjectMessagesContentProps) {
  const { messagesSettings, fetchedMessageSettings } = useMessagesSettings();

  const [pinnedMessagesView, setPinnedMessagesView] = useState<boolean>(false);

  const togglePinnedMessagesView = useCallback<() => void>(
    () => setPinnedMessagesView((prevState) => !prevState),
    []
  );

  const currentUser = useCurrentUser();

  const whiteboardKeyId = messagesSettings.withWhiteboardMessages
    ? undefined
    : { isNull: true };

  const {
    addMessageCache,
    changeMessagesFilters,
    filterMessages,
    hasNextMessagesPage,
    hasPreviousMessagesPage,
    loadMoreMessages,
    loadMorePreviousMessages,
    messages,
    messagesError,
    messagesFetched,
    messagesFetchingNextPage,
    messagesFetchingPreviousPage,
    messagesFilters,
    messagesIsPlaceholderData,
    refetchMessages
  } = useMessagesWithCustomPagination<FetchProjectMessagesQueryResponse>({
    cacheKey: ProjectCache.messagesCacheKey(project.nanoId),
    query: FETCH_PROJECT_MESSAGES,
    initialFilters: {
      projectNanoId: project.nanoId,
      whiteboardKeyId,
      messageTypeTextFilter: {
        notIn: chatGptMessagesTypes
      }
    }
  });

  const messagesWithProject = useMemo<ItemMessagesListMessages>(
    () =>
      map(messages, (message) => ({
        ...message,
        project
      })),
    [messages, project]
  );

  const {
    createMessageStateKey,
    messageInitialValues,
    handleAfterCreateMessage,
    handleCreateMessageFormStateChange,
    handleSetRepliedMessageId
  } = useItemCreateMessageFormState({
    scope: ItemCreateMessageFormStateScope.TASK_OR_PROJECT
  });

  const onAfterCreateMessage = useCallback(
    (message: FetchProjectMessagesQueryResponse) => {
      handleAfterCreateMessage();
      addMessageCache(message);
    },
    [handleAfterCreateMessage, addMessageCache]
  );

  const {
    createMessageInProjectLoading,
    sendingMessages,
    handleCreateMessageInProject,
    handleRemoveSendingMessage
  } = useProjectMessagesContentCreateMessage({
    project,
    onAfterCreateMessage
  });

  return (
    <Fragment>
      {!pinnedMessagesView ? (
        <ItemCreateMessageForm
          filterMessages={filterMessages}
          key={createMessageStateKey}
          isLoading={createMessageInProjectLoading}
          messageInitialValues={messageInitialValues}
          messages={messages}
          messagesFilters={messagesFilters}
          onAfterChatGptCommand={refetchMessages}
          projectNanoId={project.nanoId}
          withFullscreenDropzone={currentUser.hasPermissions(
            ProjectsPermissions.READ_PROJECT_CREATE_MESSAGE_FORM_FULLSCREEN_DROPZONE
          )}
          withClientLocalTime={currentUser.hasPermissions(
            ProjectsPermissions.READ_PROJECT_CLIENT_LOCAL_TIME
          )}
          withArchivizerLocalTime={currentUser.hasPermissions(
            ProjectsPermissions.READ_PROJECT_ARCHIVIZER_TEAM_LOCAL_TIME
          )}
          withPasteFiles={currentUser.hasPermissions(
            ProjectsPermissions.READ_PROJECT_CREATE_MESSAGE_FORM_PASTE_FILES
          )}
          withVisibleForClientsButton={currentUser.hasPermissions(
            MessagesPermissions.READ_MESSAGE_VISIBLE_FOR_CLIENTS_BUTTON
          )}
          withAttachProductsButton
          withAttachLifestylesButton
          withAttachMaterialsButton={currentUser.hasPermissions(
            MessagesPermissions.READ_MESSAGE_ATTACH_MATERIALS_BUTTON
          )}
          onChange={handleCreateMessageFormStateChange}
          onSubmit={handleCreateMessageInProject}
          teamNanoId={project.team?.nanoId}
          sendToProjectId={project.id}
          withLexical
          withMarkdownHelp
        />
      ) : null}

      {project && fetchedMessageSettings ? (
        <ProjectMessagesContentMessagesList
          projectNanoId={project.nanoId}
          projectUuid={project.uuid}
          sendingMessages={sendingMessages}
          onReplyMessage={handleSetRepliedMessageId}
          onRemoveSendingMessage={handleRemoveSendingMessage}
          pinnedMessagesView={pinnedMessagesView}
          togglePinnedMessagesView={togglePinnedMessagesView}
          messages={messagesWithProject}
          messagesError={messagesError}
          messagesFetched={messagesFetched}
          messagesFetchingNextPage={messagesFetchingNextPage}
          messagesFetchingPreviousPage={messagesFetchingPreviousPage}
          messagesIsPlaceholderData={messagesIsPlaceholderData}
          hasNextMessagesPage={hasNextMessagesPage}
          hasPreviousMessagesPage={hasPreviousMessagesPage}
          loadMoreMessages={loadMoreMessages}
          loadMorePreviousMessages={loadMorePreviousMessages}
          changeMessagesFilters={changeMessagesFilters}
          messagesFilters={messagesFilters}
          filterMessages={filterMessages}
        />
      ) : null}

      <DashboardActiveTaskProjectMessagesHeader project={project} />
    </Fragment>
  );
}

export default DashboardActiveTaskProjectMessagesContent;
