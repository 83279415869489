import { gql } from 'graphql-request';

import { MayBe } from '../../../types';

import {
  FileAttachmentID,
  FileAttachmentUUID,
  FileAttachmentName,
  FileAttachmentFile,
  FileAttachmentSize,
  FileAttachmentCreatedAt,
  FileAttachmentWidth,
  FileAttachmentHeight,
  FileAttachmentDPI,
  FileAttachmentUserNanoId,
  FileAttachmentUserFullName,
  FileAttachmentUserImageUUID,
  FileAttachmentUserImageFile,
  FileAttachmentVisibleForClients,
  FileAttachmentDrawing,
  FileAttachmentReference,
  FileAttachmentThreeD,
  FileAttachmentTexture,
  FileAttachmentNanoID,
  FileAttachmentUserClient,
  FileAttachmentUserCurrentTeamNanoID,
  FileAttachmentWhiteboardKeyID,
  FileAttachmentResultVersion,
  FileAttachmentOriginalFileName
} from '../fileAttachmentsTypes';

export interface FetchFileAttachmentsQueryResponse {
  id: FileAttachmentID;
  uuid: FileAttachmentUUID;
  nanoId: FileAttachmentNanoID;
  name: FileAttachmentName;
  originalFilename: FileAttachmentOriginalFileName;
  file: FileAttachmentFile;
  size: FileAttachmentSize;
  createdAt: FileAttachmentCreatedAt;
  width: FileAttachmentWidth;
  height: FileAttachmentHeight;
  dpi: FileAttachmentDPI;
  visibleForClients: FileAttachmentVisibleForClients;
  drawing?: FileAttachmentDrawing;
  reference?: FileAttachmentReference;
  resultVersion: FileAttachmentResultVersion;
  threeD?: FileAttachmentThreeD;
  texture?: FileAttachmentTexture;
  user: {
    nanoId: FileAttachmentUserNanoId;
    fullName: FileAttachmentUserFullName;
    client: FileAttachmentUserClient;
    currentTeam: MayBe<{
      nanoId: FileAttachmentUserCurrentTeamNanoID;
    }>;
    image: {
      uuid: FileAttachmentUserImageUUID;
      file: FileAttachmentUserImageFile;
    };
  };
  whiteboardKeyId: FileAttachmentWhiteboardKeyID;
}

export const FETCH_FILE_ATTACHMENTS_QUERY = gql`
  query FileAttachments(
    $filters: FileAttachmentsFilters
    $sort: [FileAttachmentsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    fileAttachments(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        nanoId
        name
        originalFilename
        file
        size
        createdAt
        width
        height
        dpi
        visibleForClients
        reference
        resultVersion
        drawing
        texture
        threeD
        user {
          nanoId
          fullName
          client
          currentTeam {
            nanoId
          }
          image {
            uuid
            file
          }
        }
        whiteboardKeyId
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
