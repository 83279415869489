import {
  BooleanFilter,
  CreatedAt,
  CreatedAtFilter,
  CreateItemGqlQuery,
  CreateItemGqlStatus,
  DeleteItemCacheKey,
  DeleteItemGqlQuery,
  DeleteItemGqlStatus,
  DeviceNanoId,
  FetchItemCacheKey,
  FetchItemGqlQuery,
  FetchItemsCacheKey,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  FileName,
  FileSize,
  FileUrl,
  ID,
  ItemGqlError,
  ModelBrand,
  NanoID,
  NanoIDFilter,
  IdFilterType,
  UpdatedAt,
  UpdateItemCacheKey,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus,
  UUID,
  UUIDFilter
} from '../../types';
import {
  UserFullName,
  UserImageFile,
  UserImageUUID,
  UserNanoID,
  UserClient,
  UserCurrentTeamNanoID,
  UserID
} from '../users/usersTypes';
import { UpdateInfiniteIndexQueryItemCacheAction } from '../common/hooks/base/reactQuery/useInfiniteIndexQuery';
import { WhiteboardKeyID } from '../whiteboards/whiteboardsTypes';

export type FileAttachmentID = ID;
export type FileAttachmentUUID = UUID;
export type FileAttachmentNanoID = ModelBrand<NanoID, 'FileAttachmentNanoID'>;
export type FileAttachmentType = 'file_attachment';
export type FileAttachmentName = string;
export type FileAttachmentOriginalFileName = string;
export type FileAttachmentFile = FileUrl;
export type FileAttachmentFileName = FileName;
export type FileAttachmentSize = FileSize;
export type FileAttachmentDrawing = boolean;
export type FileAttachmentReference = boolean;
export type FileAttachmentThreeD = boolean;
export type FileAttachmentTexture = boolean;
export type FileAttachmentPresignedUrl = string;
export type FileAttachmentPresignedUrls = string[];
export type FileAttachmentCreatedAt = CreatedAt;
export type FileAttachmentUpdatedAt = UpdatedAt;
export type FileAttachmentExt = string;
export type FileAttachmentDeviceNanoId = DeviceNanoId;
export type FileAttachmentWidth = number;
export type FileAttachmentHeight = number;
export type FileAttachmentDPI = number;
export type FileAttachmentResultVersion = number;
export type FileAttachmentWhiteboardKeyID = WhiteboardKeyID;
export type FileAttachmentWhiteboardKeyAt = CreatedAt;
export type FileAttachmentWhiteboardKeyUserID = UserID;
export type FileAttachmentWhiteboardKeyUserNanoID = UserNanoID;
export type FileAttachmentWhiteboardKeyUserFullName = UserFullName;
export type FileAttachmentWhiteboardKeyUserImageUUID = UserImageUUID;
export type FileAttachmentWhiteboardKeyUserImageFile = UserImageFile;

export type FileAttachmentUserNanoId = UserNanoID;
export type FileAttachmentUserFullName = UserFullName;
export type FileAttachmentUserImageUUID = UserImageUUID;
export type FileAttachmentUserImageFile = UserImageFile;
export type FileAttachmentUserClient = UserClient;
export type FileAttachmentUserCurrentTeamNanoID = UserCurrentTeamNanoID;
export type FileAttachmentVisibleForClients = boolean;

export type UpdateFileAttachmentCacheQuery<T> =
  UpdateInfiniteIndexQueryItemCacheAction<T>;

export type ResultVersionFilterType = {
  eq?: number;
  gt?: number;
  gte?: number;
  in?: number[];
  isNotNull?: number;
  isNull?: number;
  lt?: number;
  lte?: number;
  notEq?: number;
};

export interface FetchFileAttachmentsFilters {
  taskUuid?: UUIDFilter;
  taskNanoId?: NanoIDFilter;
  projectUuid?: UUIDFilter;
  projectNanoId?: NanoIDFilter;
  result?: BooleanFilter;
  resultVersion?: ResultVersionFilterType;
  visibleForClients?: BooleanFilter;
  ext?: { notIn?: string[] } | { in?: string[] };
  createdAt?: CreatedAtFilter;
  taskProfileUserNanoId?: UserNanoID;
  teamNanoId?: NanoIDFilter;
  id?: IdFilterType;
}

export enum FetchFileAttachmentsSortableFields {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt'
}

export enum FetchFileAttachmentsSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchFileAttachmentsGqlQuery = FetchItemsGqlQuery;
export type FetchFileAttachmentGqlQuery = FetchItemGqlQuery;
export type CreateFileAttachmentGqlQuery = CreateItemGqlQuery;
export type UpdateFileAttachmentGqlQuery = UpdateItemGqlQuery;
export type DeleteFileAttachmentGqlQuery = DeleteItemGqlQuery;
export type RemoveFileAttachmentGqlQuery = UpdateItemGqlQuery;
export type DownloadFileAttachmentGqlQuery = UpdateItemGqlQuery;

export type CreateFileAttachmentGqlStatus = CreateItemGqlStatus;
export type UpdateFileAttachmentGqlStatus = UpdateItemGqlStatus;
export type DeleteFileAttachmentGqlStatus = DeleteItemGqlStatus;
export type RemoveFileAttachmentGqlStatus = UpdateItemGqlStatus;
export type DownloadFileAttachmentGqlStatus = UpdateItemGqlStatus;
export type FileAttachmentGqlError = ItemGqlError;

export type FetchFileAttachmentsCacheKey = FetchItemsCacheKey;
export type FetchFileAttachmentCacheKey = FetchItemCacheKey;

export type FetchFileAttachmentsFetched = FetchItemsFetched;
export type FetchFileAttachmentsErrorMessage = FetchItemsErrorMessage;
export type FetchFileAttachmentsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchFileAttachmentsPage = FetchItemsPage;
export type FetchFileAttachmentsLimit = FetchItemsLimit;
export type FetchFileAttachmentsSort = FetchFileAttachmentsSortTypes[];
export type FetchFileAttachmentsTotalCount = FetchItemsTotalCount;
export type FetchFileAttachmentsSortFileAttachments = FetchItemsSortItems;
export type FetchFileAttachmentsPaginateFileAttachments =
  FetchItemsPaginateItems;
export type FetchFileAttachmentsPrefetchFileAttachments =
  FetchItemsPrefetchItems;
export type FetchFileAttachmentsShowFileAttachment = FetchItemsShowItem;
export type FetchFileAttachmentsEditFileAttachment = FetchItemsEditItem;

export type DeleteFileAttachmentCacheKey = DeleteItemCacheKey;
export type DeleteFileAttachmentCacheKeys = DeleteFileAttachmentCacheKey[];

export type RemoveFileAttachmentCacheKey = UpdateItemCacheKey;
export type RemoveFileAttachmentCacheKeys = RemoveFileAttachmentCacheKey[];
