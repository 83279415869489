import {
  FileAttachmentName,
  FileAttachmentOriginalFileName
} from '../../fileAttachmentsTypes';

interface FileAttachment {
  name: FileAttachmentName;
  originalFilename?: FileAttachmentOriginalFileName;
}

function getFileAttachmentName(fileAttachment: FileAttachment) {
  return fileAttachment.originalFilename || fileAttachment.name;
}

export default getFileAttachmentName;
