import { gql } from 'graphql-request';
import {
  ProjectUserClient,
  ProjectUserNDA
} from '../../projects/projectsTypes';

import {
  TaskName,
  TaskUUID,
  TaskCreatedAt,
  TaskProjectNanoID,
  TaskProjectName,
  TaskVisibleResultFileAttachmentsID,
  TaskVisibleResultFileAttachmentsUUID,
  TaskVisibleResultFileAttachmentsName,
  TaskVisibleResultFileAttachmentsFile,
  TaskVisibleResultFileAttachmentsSize,
  TaskVisibleResultFileAttachmentsCreatedAt,
  TaskVisibleResultFileAttachmentsWidth,
  TaskVisibleResultFileAttachmentsHeight,
  TaskVisibleResultFileAttachmentsDPI,
  TaskNanoID,
  TaskSupportMark,
  TaskMark,
  TaskStatus,
  TaskSourceFilesCount,
  TaskID,
  TaskProjectID,
  TaskResultsUploadedAt,
  TaskProjectTeamNda,
  TaskVisibleResultFileAttachmentsWhiteboardKeyID,
  TaskState,
  TaskVisibleResultFileAttachmentsOriginalFileName,
  TaskImplementationDate
} from '../tasksTypes';

export interface FetchTasksResultsQueryResponse {
  id: TaskID;
  uuid: TaskUUID;
  nanoId: TaskNanoID;
  name: TaskName;
  createdAt: TaskCreatedAt;
  supportMark: TaskSupportMark;
  mark: TaskMark;
  status: TaskStatus;
  state: TaskState;
  sourceFilesCount: TaskSourceFilesCount;
  implementationDate: TaskImplementationDate;
  projectId: TaskProjectID;
  project: {
    nanoId: TaskProjectNanoID;
    name: TaskProjectName;
    team: {
      nda: TaskProjectTeamNda;
    };
    user: {
      client: ProjectUserClient;
      nda: ProjectUserNDA;
    };
  };
  resultsUploadedAt: TaskResultsUploadedAt;
  resultFileAttachments: {
    id: TaskVisibleResultFileAttachmentsID;
    uuid: TaskVisibleResultFileAttachmentsUUID;
    name: TaskVisibleResultFileAttachmentsName;
    originalFilename: TaskVisibleResultFileAttachmentsOriginalFileName;
    file: TaskVisibleResultFileAttachmentsFile;
    size: TaskVisibleResultFileAttachmentsSize;
    createdAt: TaskVisibleResultFileAttachmentsCreatedAt;
    width: TaskVisibleResultFileAttachmentsWidth;
    height: TaskVisibleResultFileAttachmentsHeight;
    dpi: TaskVisibleResultFileAttachmentsDPI;
    whiteboardKeyId: TaskVisibleResultFileAttachmentsWhiteboardKeyID;
  }[];
}

export const FETCH_TASKS_RESULTS_QUERY = gql`
  query TasksResults(
    $filters: TasksFilters
    $sort: [TasksSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    tasks(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        uuid
        nanoId
        name
        createdAt
        supportMark
        mark
        status
        state
        sourceFilesCount
        implementationDate
        projectId
        project {
          nanoId
          name
          team {
            nda
          }
          user {
            client
            nda
          }
        }
        resultsUploadedAt
        resultFileAttachments {
          id
          uuid
          file
          size
          createdAt
          name
          originalFilename
          width
          height
          dpi
          whiteboardKeyId
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
